import React from 'react';
//import Grid from '@mui/material/Grid';
import VideoComponent from '../Video';
import ExperienceCard from '../Cards/ExperienceCard';
//import video from '../../videos/data-encrypting.mp4';

const Experience = () => {
	return (
		<section class="section-experience">
			<div class="row">
				<div class="u-center-text u-margin-bottom-big">
					<h2 class="heading-secondary">Experience</h2>
				</div>
				<ExperienceCard
					company="Aviso Wealth"
					companyUrl="https://www.aviso.ca"
					description="National Integrated Financial Services Company"
					date="2022-Present"
					role="Frontend Engineer"
				/>
				<ExperienceCard
					company="Manulife"
					companyUrl="https://www.manulife.ca"
					description="Canadian Multi-National Insurance and Financial Services
							Provider"
					date="2008-2021"
					role="Frontend Engineer, Scrum Master, Technical Product Owner"
				/>
				<ExperienceCard
					company="Quarry"
					companyUrl="https://www.quarry.com"
					description="Marketing Communications and Advertising Agency"
					date="2000-2008"
					role="Flash/Web Developer"
				/>
				<VideoComponent />

				<div class="u-anchor" id="contact">
					&nbsp;
				</div>
			</div>
		</section>
	);
};

export default Experience;
