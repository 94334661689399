import React from 'react';
import Grid from '@mui/material/Grid';
import PhotoOne from '../../img/Joe-IAM.jpg';
import PhotoTwo from '../../img/Joe-Bass.jpg';
import PhotoCert1 from '../../img/BADGES_FINAL_PSM-I_600.png';
import PhotoCert2 from '../../img/BADGES_FINAL_PSPO-I_600.png';

const About = () => {
	return (
		<section className="section-about">
			<div className="row">
				<div className="u-center-text u-margin-bottom-big">
					<h2 className="heading-secondary">About Me</h2>
				</div>

				<Grid container spacing={6} justify="center">
					<Grid item xs={12} md={6} lg={6.5} xl={8}>
						<h3 className="heading-tertiary u-margin-bottom-small">
							Hi, I am Joe! I have many years of experience in web and software
							development. I'm located in beautiful southern Ontario, Canada.
						</h3>
						<p className="paragraph">
							I am a Frontend Developer, Scrum Master and Product Owner. I have
							a passion for teamwork and I thrive in a collaborative, productive
							work environment. I am driven to design/code great user
							experiences.
							<br />
							When I'm not working, you'll find me spending time with my family,
							playing music or working on my next DIY project!
						</p>
					</Grid>
					<Grid item xs={6} sm={6} md={6} lg={5.5} xl={4}>
						<div class="composition">
							<img
								src={PhotoOne}
								aria-hidden
								alt="Photo of myself"
								className="composition__photo composition__photo--p1"
							/>
							<img
								src={PhotoTwo}
								aria-hidden
								alt="Photo of myself playing Bass"
								className="composition__photo composition__photo--p2"
							/>
						</div>
					</Grid>
					<Grid item xs={6} sm={6} md={6}>
						<div className="composition">
							<a
								href="https://www.scrum.org/user/826953"
								target="_new"
								rel="noreferrer"
							>
								<img
									src={PhotoCert1}
									alt="PSM!"
									className="composition__cert-images"
								/>
							</a>
							<a
								href="https://www.scrum.org/user/826953"
								target="_new"
								rel="noreferrer"
							>
								<img
									src={PhotoCert2}
									alt="PSPO!"
									className="composition__cert-images"
								/>
							</a>
						</div>
					</Grid>
				</Grid>

				<div className="u-anchor" id="experience">
					&nbsp;
				</div>
			</div>
		</section>
	);
};

export default About;
