import React from 'react';

const boxColor = [
	'card__picture card__picture--1',
	'card__picture card__picture--2',
	'card__picture card__picture--3',
];
const titleColor = [
	'card__heading-span card__heading-span--1',
	'card__heading-span card__heading-span--2',
	'card__heading-span card__heading-span--3',
];
const btnColor = ['btn btn--green', 'btn btn--blue', 'btn btn--purple'];
const skillsCardColor = [
	'card__side card__side--back card__side--back-1',
	'card__side card__side--back card__side--back-2',
	'card__side card__side--back card__side--back-3',
];

const HomeCard = ({ id, title, description, cta, skills }) => {
	return (
		<div className="card">
			<div className="card__side card__side--front">
				<div className={boxColor[id - 1]}>&nbsp;</div>
				<h3 className="card__heading">
					<span className={titleColor[id - 1]}>{title}</span>
				</h3>
				<div className="card__cta">
					<div className="card__details">
						<p className="description">{description}</p>
					</div>
					<div className="u-center-text u-margin-top-huge">
						<a href="#Learn" className={btnColor[id - 1]}>
							{cta}
						</a>
					</div>
				</div>
			</div>
			<div className={skillsCardColor[id - 1]}>
				<div className="card__cta">
					<div className="card__skills">
						<p>Skills:</p>
						<ul>
							{skills.map((skill) => (
								<li>{skill}</li>
							))}
						</ul>
					</div>
				</div>
			</div>
			{/* {(() => {
				if (id === 1) {
					return <FedSkill />;
				} else if (id === 2) {
					return <SmSkill />;
				} else {
					return <PoSkill />;
				}
			})()} */}
		</div>
	);
};

export default HomeCard;
