import React from 'react';
import Grid from '@mui/material/Grid';
import HomeCard from '../Cards/HomeCard';

const Home = () => {
	return (
		<section className="section-home" id="home">
			<div className="u-center-text u-margin-bottom-big">
				<div className="row">
					<Grid container spacing={6} justify="center">
						<Grid item xs={12} sm={12} md={4}>
							<HomeCard
								id={1}
								title="Frontend Developer"
								description="I have a passion for teamwork and I thrive in a collaborative,
							productive work environment"
								cta="Learn More"
								skills={[
									'Javascript (ES5/ES6)',
									'React',
									'HTML5',
									'CSS3/SASS/SCSS/LESS',
									'Bootstrap/Material UI',
									'Tailwind CSS/Gulp',
									'Responsive Design',
									'Adobe Experience Manager',
									'jQuery/Ajax/D3.js',
									'Jest/Selenium',
									'Git/GitHub/GitLab',
								]}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={4}>
							<HomeCard
								id={2}
								title="Scrum Master"
								description="As a certified professional Scrum Master, I am well versed in the
								Agile Scrum framework."
								cta="Learn More"
								skills={[
									'PSM1 Certification',
									'Agile Scrum/Kanban',
									'JIRA/Confluence',
									'Servant Leadership',
									'Coaching Abilities',
									'Scrum Event Facilitator',
									'Organizational Skills',
									'Conflict Facilitation',
									'Flexibility and Persistence',
									'Technical Experience',
								]}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={4}>
							<HomeCard
								id={3}
								title="Product Owner"
								description="5+ years of working experience in Scrum with strong technical
								knowledge and expertise."
								cta="Learn More"
								skills={[
									'PSPO1 Certification',
									'Agile Scrum/Kanban',
									'JIRA/Confluence',
									'Product Backlog Management',
									'Epics and User Stories',
									'Google Analytics',
									'Tableau',
									'Prioritization Skill',
									'Accessibility for Ontarians with Disabilities Act (AODA)',
									'Product Developement Life Cycle',
								]}
							/>
						</Grid>
					</Grid>
				</div>
			</div>
			<div className="u-anchor" id="about">
				&nbsp;
			</div>
		</section>
	);
};

export default Home;
