import React from 'react';
import Grid from '@mui/material/Grid';
import CustomTagRenderer from '../CustomTagRenderer';

const Contact = () => {
	return (
		<section className="section-contact">
			<div className="row">
				<div className="u-center-text u-margin-bottom-medium">
					<h2 className="heading-secondary">Contact</h2>
				</div>
			</div>
			<Grid container spacing={3} justify="center">
				<Grid item xs={12} sm={4}>
					<div className="container-contact__item">
						<a href="mailto:joe_freitas001@gmail.com">
							<i className="far fa-envelope container-contact__item--1"></i>
							<div className="container-contact__item">Email</div>
						</a>
					</div>
				</Grid>
				<Grid item xs={12} sm={4}>
					<div className="container-contact__item">
						<a
							href="https://www.linkedin.com/in/joe-luis-freitas/"
							target="_blank"
							rel="noreferrer"
						>
							<i className="fab fa-linkedin container-contact__item--2"></i>
							<div className="container-contact__item">LinkedIn</div>
						</a>
					</div>
				</Grid>
				<Grid item xs={12} sm={4}>
					<div className="container-contact__item">
						<a
							href="https://github.com/joschmo12/"
							target="_blank"
							rel="noreferrer"
						>
							<i className="fab fa-github container-contact__item--3"></i>
							<div className="container-contact__item">GitHub</div>
						</a>
					</div>
				</Grid>
				<Grid item xs={12}>
					<div className="heading-techstack">
						<p className="heading-tertiary">
							Technolgy Stack used for this site:
						</p>
					</div>
				</Grid>
				<Grid item xs={12}>
					<div className="cloudtag">
						<CustomTagRenderer />
					</div>
				</Grid>
			</Grid>
		</section>
	);
};

export default Contact;
