import React from 'react';
import { TagCloud } from 'react-tagcloud';

const data = [
	{ value: 'JavaScript', count: 32 },
	{ value: 'React', count: 36 },
	{ value: 'SASS/SCSS', count: 28 },
	{ value: 'Material UI', count: 23 },
	{ value: 'HTML5', count: 27 },
	{ value: 'CSS3', count: 25 },
	{ value: 'React TagCloud', count: 21 },
	{ value: 'ES6+', count: 26 },
	{ value: 'Jest', count: 24 },
	{ value: 'React Router Hash Link', count: 20 },
	{ value: 'NPM', count: 18 },
];

// custom renderer is function which has tag, computed font size and
// color as arguments, and returns react component which represents tag
const customRenderer = (tag, size, color) => (
	<span
		key={tag.value}
		style={{
			animation: 'blinker 4s linear infinite',
			animationDelay: `${Math.random() * 2}s`,
			fontSize: `${size / 2}em`,
			border: `2px solid ${color}`,
			margin: '3px',
			padding: '3px',
			display: 'inline-block',
			color: 'black',
		}}
	>
		{tag.value}
	</span>
);

export default () => (
	<TagCloud tags={data} minSize={1} maxSize={5} renderer={customRenderer} />
);
