import React from 'react';
import imageJoe from '../../img/JoeFreitas.png';

const Header = () => {
	return (
		<header className="header">
			<div className="header__image-box">
				<img src={imageJoe} alt="Joe Freitas" className="header__image" />
			</div>
			<div className="header__title-box">
				<h1 className="heading-primary">
					<span className="heading-primary--main">Joe Freitas</span>
				</h1>
			</div>

			<div className="header__nav-box"></div>
		</header>
	);
};

export default Header;
