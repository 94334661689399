import React from 'react';
import { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Spin as Hamburger } from 'hamburger-react';

const NavBar = () => {
	const [isOpen, setOpen] = useState(false);

	return (
		<nav className="nav">
			<label for="toggle" class="nav__button">
				<Hamburger
					toggled={isOpen}
					toggle={setOpen}
					className="nav__hamburger"
				/>
			</label>
			{/* <label for="toggle">&#9776;</label> */}
			<input type="checkbox" class="nav__checkbox" id="toggle" />

			<ul className="nav__menu">
				<li className="nav__item" for="toggle" role="link" aria-label="Home">
					<Link smooth to="#home" className="nav__link">
						Home
					</Link>
				</li>
				<li className="nav__item">
					<Link
						smooth
						to="#about"
						className="nav__link"
						role="link"
						aria-label="About"
					>
						About Me
					</Link>
				</li>
				<li className="nav__item">
					<Link
						smooth
						to="#experience"
						className="nav__link"
						role="link"
						aria-label="Experience"
					>
						Experience
					</Link>
				</li>
				<li class="nav__item">
					<Link
						smooth
						to="#contact"
						className="nav__link"
						role="link"
						aria-label="Contact"
					>
						Contact
					</Link>
				</li>
			</ul>
		</nav>
	);
};

export default NavBar;
