import React from 'react';

const Footer = () => {
	return (
		<footer className="section-footer">
			<div className="container-footer">
				<div className="container-footer__box--1"></div>
				<div className="container-footer__box--2">
					<div className="footer__copy">© 2022 Joe Freitas</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
