import React from 'react';
import BackgroundVideo from '../videos/chain.mp4';

const Video = () => {
	return (
		<>
			<video
				className="experience-section experience-section__video"
				preload="auto"
				playsInline
				autoPlay
				loop
				muted
			>
				<source src={BackgroundVideo} type="video/mp4" />
				Your browser does not support HTML5 video.
			</video>
		</>
	);
};

export default Video;
