import Header from './components/Layout/Header';
import NavBar from './components/Layout/NavBar';
import Home from './components/Pages/Home';
import About from './components/Pages/About';
import Experience from './components/Pages/Experience';
import Contact from './components/Pages/Contact';
import Footer from './components/Layout/Footer';

function App() {
	return (
		<>
			<Header />
			<NavBar />
			<Home />
			<About />
			<Experience />
			<Contact />
			<Footer />
		</>
	);
}

export default App;
