import React from 'react';
import Grid from '@mui/material/Grid';
//import VideoComponent from '../Video';
//import video from '../../videos/data-encrypting.mp4';

const ExperienceCard = ({ company, companyUrl, description, date, role }) => {
	return (
		<div class="u-margin-bottom-small">
			<div class="experience-section experience-section__box">
				<Grid container spacing={3} justify="center">
					<Grid item xs={12} sm={6} md={6}>
						<h3 class="heading-experience">
							<a href={companyUrl} target="_blank" rel="noreferrer">
								{company}
							</a>
						</h3>
						<p>{description}</p>
					</Grid>
					<Grid item xs={12} sm={6} md={6}>
						<h4 class="heading-experience__date">{date}</h4>
						<div class="experience-section__roles">
							<ul>
								<li>{role}</li>
							</ul>
						</div>
					</Grid>
				</Grid>
			</div>
		</div>
	);
};

export default ExperienceCard;
